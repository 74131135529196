p {
    margin: 0;
    padding: 0;
}

h3 {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'NeueWorld-CondensedRegular';
    src: local('NeueWorld-CondensedRegular'), url(./assets/fonts/PPNeueWorld-CondensedRegular.otf) format('opentype');
}
  
@font-face {
    font-family: 'NeueWorld-ExtendedThin';
    font-weight: 900;
    src: local('NeueWorld-ExtendedThin'), url(./assets/fonts/PPNeueWorld-ExtendedThin.otf) format('opentype');
}

@font-face {
    font-family: 'NeueWorld-SemiCondensedUltrabold';
    font-weight: 900;
    src: local('NeueWorld-SemiCondensedUltrabold'), url(./assets/fonts/PPNeueWorld-SemiCondensedUltrabold.otf) format('opentype');
}

@font-face {
    font-family: 'NeueWorld-SemiExtendedBlack';
    font-weight: 900;
    src: local('NeueWorld-SemiExtendedBlack'), url(./assets/fonts/PPNeueWorld-SemiExtendedBlack.otf) format('opentype');
}

@font-face {
    font-family: 'NeueWorld-SuperCondensedLight';
    font-weight: 900;
    src: local('NeueWorld-SuperCondensedLight'), url(./assets/fonts/PPNeueWorld-SuperCondensedLight.ttf) format('truetype');
}

@font-face {
    font-family: 'NeueWorld-Thin';
    font-weight: 900;
    src: local('NeueWorld-Thin'), url(./assets/fonts/PPNeueWorld-Thin.otf) format('opentype');
}
  