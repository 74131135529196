.appStyle {
    height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  
  .innerDiv {
    margin: auto;
    padding: 0px;
    border: 0px solid #c9c9c9;
    border-radius: 16px;
    width: 100%;
    min-width: 260px;
    display: flex;
    flex-direction: row;
    height: 100%;
    box-shadow: -9px 9px 18px #b2d3e066, 9px -9px 18px #ffffff;
    overflow: hidden;
  }
  
  .formStyle {
      margin-top: auto;
    margin: auto;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 12px;
    width: 40%;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-around;
  }
  
  .labelStyle {
    margin: 20px 0 0px 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #000000;
  }
  
  .inputStyle {
    margin: 0px ;
    padding: 16px;
    border: 2px solid #000000;
    border-radius: 3px;
    box-sizing: border-box;
    width: 100%;
    height: 80%;
  }
  
  .submitStyle {
      height: 1.6cm;
    margin: 10px 0 0 0;
    padding: 7px 10px;
    border: 1px solid #efffff;
    border-radius: 3px;
    background: #141414;
    width: 100%;
    font-size: 17px;
    color: white;
    display: block;
  }
  .submitStyle:hover{
      background-color: #7a7979;
  }


  
  .heading {
    font-family: 'NeueWorld-CondensedRegular';
  }
  
  @media (max-width: 900px) {
    .rightDiv {
     display: none;
    }
  }

  .lineBreak {
      width: 130%;
      margin-left: 70%;
    background-color: rgb(255, 180, 40);
    margin-top: 1px;
    margin-bottom: 2px;
    height: 2px;
  }
  .lineeBreak {
      width: 145%;
    background-color: rgb(255, 47, 40);
    margin-top: 3px;
    margin-bottom: 4px;
   
    height: 2px;
  }
  
  .head{
    font-size: 45px;
    font-weight: bold;
    justify-content: center;
    
}

.linkItem{
  color: white;
  text-decoration: none;
  margin: 5px;

}
