a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }
  
  html {
    font-size: 14px;
  }
  
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }
  
  .box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  }
  
  .navbar,
  .navbar-default {
    background-color: rgba(255, 255, 255, 0.89) !important;
  }
  
  .bgBlur {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-filter: blur(50px);
    background-color: rgb(255, 255, 255);
    filter: blur(50px);
    z-index: -1;
  }
  
  .menuBtn:focus  {
      outline: none;
      box-shadow: none;
  }
  .hai{
  
    font-size: 17px;
    margin-left: 50px;
  }