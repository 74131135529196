.btn {
  background-color:#5242;
  text-transform: none;
  color: white;
  font-size: small;
  border-radius: 24px !important;;
  box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.03);
  transition: background-color 0.3s, transform 0.3s !important;
  width: 200px !important;
  padding: 10px 0 !important;
}
.btn:hover {
  background-color: #3f3da5 !important;
  transform: scale(1.05) !important;
}
.textField {
  border-radius: 16px !important;
}
.font12 {
  font-size: 12px !important;
}