.joinDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 15px; */
  align-items: center;
}
.textField {
  border-radius: 16px;
}

#Courses {
  .countDiv {
    border-radius: 100%;
    /* background: #5249bd33; */
    color: black;
    padding: 8px;
    height: 40px;
    width: 40px;
  }
  .cardDiv {
    /* border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5249bd, #d3d3d300); */
    height: 100%;

    max-height: 150px;
    border-radius: 16px;
    border-bottom-width: 0px;
    /* background: #5249bd; */
    /* box-shadow: 39px 57px 83px 0px rgba(204, 204, 204, 0.25); */
  }
  .cardFooter {
    border-top: 1px solid lightgray;
  }
  /* .cardDiv :hover {
    background: #5249bd;
    color: white;
  } */
  .curCard {
    border-radius: 16px;
    height: 100%;
    max-height: 700px;
    display: flex;
    background-size: cover !important;
    flex-direction: column;
    /* align-items: center; */
    gap: 15px;
    padding: 20px;
    justify-content: center;
    background: #5249bd;
    background-image: url("../../../assets/images/curBg.png");
    color: white;
  }
  .carousel .control-dots {
    text-align: left;
  }
  .btn {
    width: 100%;
    max-width: 120px;
    height: 35px;
    /* border-radius: 80px; */
    /* border: 1px solid #5249bd; */
    text-transform: none;
    color: #5249bd;
    font-size: 12px;
    text-align: left;
    /* background: #fff; */
    /* box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.03); */
  }
  .topleftBorder {
    /* border-top: 2px solid;
    border-left: 2px solid;
    border-image: linear-gradient(to right, #5956e9, #d3d3d300) 2 0 0 0; */
  }
}
#trustedTeams {
  .imgDiv {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding: 20px 280px;
    justify-content: center;
  }
}
#stories {
  .ratingDiv {
    background: #faf9fc;
    width: 300px;
  }
  .imgDiv {
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
    gap: 30px;
  }
  .cardDiv {
    /* height: 100%;
    max-height: 200px; */
    /* border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5249bd, #d3d3d300); */
    border-radius: 30px;
    border-bottom-width: 0px;
    /* background: #5249bd; */
    /* box-shadow: 39px 57px 83px 0px rgba(204, 204, 204, 0.25); */
  }
  .cardDiv :hover {
    /* background: #5249bd;
    color: white;
    .linedIn {
      fill: white;
    } */
  }
  .linedIn {
    fill: #68a3da;
  }
  .linedIn :hover {
    fill: white;
  }
  .btn {
    border-radius: 80px;
    background: #5249bd;
    text-transform: none;
    color: white;
    font-size: small;
    box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.03);
  }
}
#teams {
  .cardDiv {
    border-radius: 16px;
    width: 150px;
    height: 200px;
  }
  .slick-next {
    fill: #5249bd;
  }
  .slick-prev {
    fill: #5249bd;
  }
}
#whyus {
  padding: 30px 60px;
  .cardDiv {
    border-radius: 16px;
    height: 180px;
    width: 100%;
    max-width: 400px;
    @media (max-width: 760px) {
      width: 100%;
      height: 150px;
      max-width: 500px;
      /* height: auto; */
    }
  }
  @media (max-width: 760px) {
    padding: 30px 30px;
  }
  .icon {
    width: 80px;
    height: 80px;
  }
}
#footer {
  .btn {
    border-radius: 80px;
    border: 1px solid #5249bd;
    text-transform: none;
    background: #5249bd;
    color: #fff;
    box-shadow: 0px 20px 24px 0px rgba(0, 0, 0, 0.03);
  }
}
#login {
  .tabDiv {
    width: 100%;
    max-width: 300px;
    height: 50px;
    border-radius: 30px;
    background: #5249bd;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  .tab {
    width: 100%;
    max-width: 150px;
    height: 35px;
    border-radius: 16px;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }
  .activeTab {
    background: #423a9e;
  }
  .imgDiv {
    width: 100%;
    height: auto;
    max-width: 737px;
    max-height: 825px;
  }
  .textField {
    border-radius: 16px;
    width: 100%;
  }
}
.globalImg {
  width: 100%;
  max-width: 465px;
  height: 100%;
  max-height: 400px;
  @media (max-width: 560px) {
    width: 100%;
    height: auto;
  }
}
