.blog-editor-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .editor-toolbar {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    justify-content:left;
  }
  
  .toolbar-btn {
    padding: 8px 12px;
    font-size: 16px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .toolbar-btn:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .editor-wrapper {
    border: 1px solid #ddd;
    min-height: 450px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    cursor: text;
    font-size: 16px;
    overflow-y: auto;
    height: 450px; 
  }
  
  .editor-wrapper:focus-within {
    border-color: #007bff;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
    overflow-y: auto;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  
 
  .popup-loader-overlay {
    position: fixed;  /* Fixes the loader to the page */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;  /* Ensures the loader is above other content */
  }
  
  .popup-loader-overlay.hidden {
    display: none;  /* Hide the loader when `hidden` class is applied */
  }