.HomeLandingimgBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
  padding-bottom: 16px;
}

.Homesectiontwo{
  margin-top: 5%;
  display: flex;
  flex-direction: row;   
}

.gridContainer {
  max-width: 3000px;
  margin: 0 auto;
  display: grid;
  grid-gap: 16px;
  padding: 0 16px;
}

@media (max-width: 1300px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1300px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 700px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card {
  transition: 0.3s;
  height: auto;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  border: 0.5px solid #c7c7c7;
  width: 100%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.carddeatils {
  transition: 0.3s;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  width: 100%;
  border: 0.5px solid #c7c7c7;
  overflow: hidden;
}

.categoryContainerdetails{
  margin-top:3%;
  margin-left: 10%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.categoryContainer {
  width: 100%;
  transition: 00.3s;
}

.gridContainerdeatils {
  max-width: 2000px;
  margin: 0;
  display: grid;
  grid-gap: 1rem;
  /* padding: 16px; */
  padding: 2%;
  width: 100%;
}

@media (min-width: 600px) {
  .gridContainerdeatils {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 600px) {
  .gridContainerdeatils {
    grid-template-columns: repeat(2, 1fr);
  }
}

.paygridContainerdeatils {
  max-width: 2000px;
  margin: 0;
  display: grid;
  grid-gap: 1rem;
  padding-left:2%;
  padding-right:2%;
  width: 100%;
}

@media (min-width: 400px) {
  .paygridContainerdeatils {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 500px) {
  .paygridContainerdeatils {
    grid-template-columns: repeat(1, 1fr);
  }
}

.paycategoryContainerdetails{
  margin-top:1%;
  margin-left: 1%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.paycarddeatils {
  transition: 0.3s;
  height: 70px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color:  rgba(236, 231, 231, 0.467);
  color: rgb(8, 8, 8);
  border: 0.5px solid #c7c7c7;
}

.paybutton p{
  width: 200px; 
  background-color: #1065B7; text-align: center; 
  font-weight: 800; padding: 11px 0px; 
  color:white; 
  font-size: 12px; display: inline-block; text-decoration: none;
}

.videoName {
  font-weight: 500;
  padding: 8px 8px 4px 8px;
}