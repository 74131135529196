.fontSize20 {
  font-size: 20px !important;
}

.bold {
  font-weight: bold !important;
}
.fontSize30 {
  font-size: 30px !important;
}

.font12 {
  font-size: 12px !important;
}


