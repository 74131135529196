.HomeLandingBox {
  grid-gap: 5px;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 1px;
}

.HomeLandingimgBox {
  height: 100vh;
  overflow: hidden;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
}




.reviewGrid {

  padding: 20px;
  max-width: 2000px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;


}

@media (min-width: 400px) {
  .reviewGrid {
    grid-template-columns: repeat(0, 1fr);
  }
}

@media (min-width: 900px) {
  .reviewGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.reviewCard {

  box-shadow: 0 4px 8px 0 rgba(66, 64, 64, 0.39);
  transition: 0.3s;
  border-radius: 8px;
  display: flex;
  background-color: white;

  flex-direction: column;

}

.box {
  padding: 5px;
  width: 25cm;
  height: 14cm;
  border: 3px solid rgb(88, 85, 85);
  padding: 15px;
  background-color: #fcf9f9;
}


.cid {
  text-align: right;
}

.tag {
  font-family: "Segoe UI", Arial, sans-serif;
  max-width: 130%;
  margin-left: 30px;
  margin-top: 20px;

}

.complete {
  margin-left: 30px;
  font-size: small;
  color: rgb(121, 120, 120);
}

.myname {
  margin-left: 30px;
  margin-top: 20px;
}

.course {
  margin-left: 30px;
  margin-top: 50px;
  font-weight: bold;
}

.linebox {
  width: 65%;
  align-self: center;
  height: 1px;
  background-color: #bdbdbd;
  min-width: 260px;
  margin-top: 3px;
  margin-left: 30px;
}

.sbox {
  display: flex;
  flex-direction: row;
}

.footer {
  display: flex;
  flex-direction: row;

  margin-left: 170px;
  cursor: pointer;
  font-size: 14px;

}

.link {
  margin-left: 10px;
}

.Readmorebtn {
  height: 1cm;
  width: 20%;
  border-radius: 4px;
  font-family: Arial;
  color: #ffff;
  font-size: 30px;
  background: rgb(0, 0, 0);
  text-decoration: none;
  margin-top: 10px;
  font-size: 14px;
  border: 1px;
  margin-left: 38%;
}

.Readmorebtn:hover {
  background: rgb(116, 111, 111);
}

.linkItem {
  text-decoration: none;
  color: white;
  margin: 5px;

}

.linkItem:hover {
  text-decoration: none;
  color: white;
}

.validation {
  font-size: 12px;
  cursor: pointer;
  margin-left: 220px;
  margin-top: 50px;
  color: #7c7979;
}


