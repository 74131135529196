html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: #0b093b;
}
a:hover {
  color: #7620ff;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}
body {
  background: #fff;
  color: #0b093b;
  font-family: "Khula", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 0px 60px;
}
@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active {
  border-bottom: 2px solid #f1f1f1;
  color: #f1f1f1;
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
  gap: 10px;
}
.flexColumn {
  flex-direction: column !important;
}
.flexCenter {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* FONT SIZES */
.font8 {
  font-size: 8px !important;
}
.font11 {
  font-size: 0.688rem !important;
}
.font12 {
  font-size: 0.75rem !important;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem !important;
}
.font15 {
  font-size: 0.938rem !important;
}
.font18 {
  font-size: 1.125rem !important;
}
.font20 {
  font-size: 1.25rem !important;
}
.font25 {
  font-size: 1.563rem !important;
}
.font30 {
  font-size: 1.875rem !important;
}
.font40 {
  font-size: 2.5rem !important;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.813rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
  .font54 {
    font-size: 3.375rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem !important;
}
.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: #0b093b !important;
  cursor: pointer;
}
.purpleColor {
  color: #5249bd !important;
}
.orangeColor {
  color: #f2b300;
}
.lightColor {
  color: #f5f5f5;
}
.coralColor {
  color: #c4ffff;
}
.greenColor {
  color: #49cb86;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff !important;
}

.lightPurpleColor {
  color: #9966cc;
}
/* BACKGROUNDS */
.darkBg {
  background-color: #0b093b;
}
.lightPurpleBg {
  background-color: #9966cc !important;
}
.purpleBg {
  background-color: #5249bd !important;
}
.orangeBg {
  background-color: #f2b300;
}
.lightBg {
  background-color: #f5f5f5 !important;
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #707070;
}
.whiteBg {
  background-color: #fff !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.p0 {
  padding: 0 !important;
}
.p15 {
  padding: 15px !important;
}
.p30 {
  padding: 30px !important;
}
.gird1 {
  order: 1;
}
.gird2 {
  order: 2;
}

@media (max-width: 560px) {
  .gird1 {
    order: 2;
  }
  .gird2 {
    order: 1;
  }
}

.animation {
  display: flex;
  justify-content: center;
  /* margin-top: 10vh; */
  /* padding: 2rem 40px; */

  .anm_mod {
    opacity: 100%;
    transform: translate3d(0, 100%, 0);
    transition: all 1s ease;

    &.full {
      width: 100%;
      font-size: 3rem;
    }
    &.left {
      transform: translate3d(-100%, 0, 0);
    }
    &.right {
      transform: translate3d(100%, 0, 0);
    }
    &.delay {
      transition: all 2s ease;
    }
    &.fast {
      transition: all 0.8 ease;
    }
  }
}

.animation .anm_mod.activediv {
  /* opacity: 1; */
  transform: translate3d(0, 0, 0);
}

/* .org_to_top {
  position: fixed;
  bottom: 20px;
  right: 20px;
} */
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 15px;
  right: 15px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
