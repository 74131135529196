body {
  font-family: "Inter", sans-serif;
}

.divalign {
  color: white;
  display: flex;
  justify-content: center;
}

.bg {
  background-color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  /* position: fixed; */
  width: 100%;
  height: 100vh;
}

.sidenav {
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #544bbd;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 20px;
  transition: 0.5s;
}

.navItem {
  padding: 16px 12px 12px 24px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: flex;
  text-align: left;
  background: transparent;
  margin: 4px 20px 4px 20px;
  border-radius: 4px;
  flex-direction: column;
}

.navItem:hover {
  color: #f1f1f1;
  background-color: #2c2c2c;
  text-decoration: none;
}

.main {
  transition: 0.5s;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  padding-top: 52px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.topNavBar {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  transition: 0.5s;
  top: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.01);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-bottom: 0.5px solid #d7d7d7;
}

.container {
  overflow-y: scroll;
}

.logoBox {
  display: flex;
  flex-direction: row;
  margin: 0 20px 10px 20px;
}

.logo {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  padding: 0;
  margin: 0;
}

.logoTitle {
  color: #f1f1f1;
  font-size: 28px;
  margin: 0 0 0 8px;
  padding: 0;
}

.menuDrawer {
  width: 28px;
  height: 28px;
  margin-right: 16px;
  cursor: pointer;
}

.active {
  padding: 16px 12px 12px 24px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: flex;
  text-align: left;
  background-color: #2c2c2c;
  margin: 4px 20px 4px 20px;
  border-radius: 4px;
  flex-direction: column;
  text-decoration: none;
}

.active:hover {
  color: #f1f1f1;
  background-color: #2c2c2c;
  text-decoration: none;
}

.bellBox {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.bellIcon {
  right: 0;
}
