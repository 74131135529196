.HomeLandingBox {
  background-color: black;
    max-width: 2000px;
    display: grid;
    grid-gap: 1rem;
    padding: 32px 5%;
  }
  

  .reviewGrid {
  background-color: black;
    max-width: 2000px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
  
  }
  
  @media (min-width: 400px) {
    .reviewGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 900px) {
    .reviewGrid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .reviewCard {
    background-color: black;
    box-shadow: 0 4px 8px 0 rgba(66, 64, 64, 0.39);
    transition: 0.3s;
    background-color: #181628;
   border: 1px solid #23203a;
    flex-direction: column;
    box-shadow: 4 4px 8px 4 rgba(97, 219, 235, 4);
    border-radius: 5px;

  }
 
   
  .buttontop{
  width:30%;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    
   }
   .buttonbottom{
    padding: 4px;
    width:70%;
    height: 80%;
   }
   .star{
  text-align: center;
    
   }
   .name {
    color:white;
   font-size: 15px;
   text-align: center;
   font-weight: bold;
   font-family:Arial,sans-serif ;
   }
   .lin{
    text-align: center;
   }
   .year{
    color: rgb(194, 188, 188);
    font-family:Arial,sans-serif ;
   text-align: center;
    font-size: 15px;
   }
   .company{
    font-family:Arial,sans-serif ;
  text-align: center;
    font-size: 15px;
    color: rgb(194, 188, 188);
   }
   .icon{
    margin-left: 5px;
    
   }
   .comment{
    margin-top: 20px;
    font-size: 13px;
   }
   .buttonbox{
    width:100%;
display: flex;
flex-direction: row;
  background-color: #181628;


   }
   .linkedin{
margin-left: 50px;

   }

   