.HomeLandingBox {
    grid-gap: 5px;
    max-width: 100%;
    max-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 1px;
  }
  .HomeLandingMainBox {
    margin-left: 10px;
    border: 10px;
     width: 20%;
   display: flex;
   height: 100%;
   flex-direction: column;
   align-items: flex-start;
   background-color: white;
   box-shadow: 0 6px 10px 0 rgba(172, 170, 170, 0.2);
 
  }
  .HomeLandingimgBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }
  .appstore {
    height: 50px;
    width: 248px;
    font-family: Arial;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: bold;
    background-color: white;
    text-decoration: none;
    margin-top: 12px;
    border: 1px solid white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .appstoree {
    height: 50px;
    width: 248px;
    font-family: Arial;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: bold;
    background-color: rgb(223, 217, 217);
    text-decoration: none;
    margin-top: 12px;
    border: 1px solid white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .appstore:hover {
    background-color: rgb(243, 235, 235);
  }
  
  .appstore:focus {
    outline: none;
  }
  .appstoreText{
      color: black;
    padding: 0px;
    margin: 0px;
    margin-left: 12px;
  }
  .appstoreTextt{
    color: black;
  padding: 0px;
  margin: 0px;
  margin-left: 12px;
}
  .Homesectionone{
      padding: 5px;
      width:100%;
     
  }

  .submitStyle {
    height: 2cm;
    margin: 10px 0 0 0;
    padding: 8px 10px;
    border-radius: 3px;
    background: rgb(255, 255, 255) ;
    width: 125%;
    font-size: 20px;
    display: flex;
    font-weight: bold;
      box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
      transition: 0.3s;
      height: 50px;
      border-radius: 8px;
      background-color: rgb(255, 255, 255);
      
      width: 100%;
  }
    

  .Homesectiontwo{
      width: 100%;
      height: 60%;
  }
  
 

  .card {
    box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.119);
    border: 1px rgb(202, 194, 194);
    transition: 0.3s;
    height: 50px;
    width: 1080px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(7, 6, 6, 0.5);
  }
  
  .name {
    margin-left: 5px;
    padding: 2px 16px;
    margin-top: 13px;
  }
  
  .gridContainer {
    max-width: 2000px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    /* padding: 16px; */
    padding: 25px 1%;
  }
  
  @media (min-width: 600px) {
    .gridContainer {
      grid-template-columns: repeat(0, 5fr);
    }
  }
  
  @media (min-width: 900px) {
    .gridContainer {
      grid-template-columns: repeat(1, 6fr);
    }
  }
  
  

  
  