
.container {

  height: 100vh;
  width: 100%;
}



.form {
margin-top: auto;
margin: auto;
  padding-left: 10%;
  padding-right: 10%;
  width: 70%;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  height: 50%;

}

.inputStyle {
  margin-left: 10px;
  color: rgb(3, 3, 3);
  border: #efffff;
  width: 100%;
  height: 80%;
}

.inputStyle:focus {
  outline: none;
}




.loginBtn {
  height: 52px;
  padding: 9px 12px;
  border-radius: 2px;
  background: #141414;
  width: 100%;
  font-size: 17px;
  color: white;
  border-width: 0px;
}

.loginBtn:focus {
  outline: none;
}

.input {

  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #7e7e7e;
  padding: 15px 20px;
  margin-top: 15px;
  width: 100%;
  margin-right: 20%;
  color: grey;
  border-radius: 2px;
}
.title{
  max-width: 500px;
  font-size: 22px;
  font-weight: 50px;
}
.titleone{
  width: 500px;
  margin-top: 30px;
  font-family: 'NeueWorld-CondensedRegular';
  color: black;
}
.Readmorebtn {
  height: 80px;
  width: 18cm;
  border-radius: 4px;
  font-family: Arial;
  color: #ffff;
  font-size: 30px;
  background: rgb(0, 0, 0);
  text-decoration: none;
  margin-top: 10px;
  font-size: 17px;
  border: 1px;
}

.Readmorebtn:hover {
  background: rgb(116, 111, 111);
 
}
.linkItem{
  text-decoration: none;
  color: white;
  margin: 5px;
}
.pha{
  display:flex;
  flex-direction: column;
}
.validerror{
  color: red;
}


