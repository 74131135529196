.container {
    height: 60vh;
    width: 100%;
  }
    
  .innerDiv { 
    padding: 0px;
    width: 100%;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  .validerror{
    color: red;
  }
    
  .form {
    margin: auto;
    width: 50%;
    padding-left: 10%;
    padding-right: 10%;
    min-width: 340px;
    display: flex;
    flex-direction: column;
  }
    
  .labelStyle {
    margin: 20px 0 0px 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #000000;
  }
    
  .inputStyle {
    margin: 0px ;
    padding: 10px;
    border: 1px solid #3b3b3b;
    border-radius: 2px;
    box-sizing: border-box;
    width: 100%;
    height: 48x;
  }
  
  .inputStyle:focus {
    outline: none;
  }
    
  .submitBtn {
    height: 52px;
    padding: 7px 10px;
    border-radius: 2px;
    background: #202020;
    width: 100%;
    font-size: 17px;
    color: white;
    display: block;
    border-width: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  
  .submitBtn:hover {
    background-color: #000000;
  }
  
  .submitBtn:focus {
    outline: none;
  }
  
  .heading {
    margin-top: 30px;
    justify-content: center;
    align-self: center;
    margin-bottom: 12px;
    font-size: 17px;
    font-weight: bold;
  
  }
  
  .linkItem{
    color: white;
    font-weight: 500;
    text-decoration: none;
    margin: 1px;
    font-size: 16px;
  }
  
  .linebox {
    width: 50%;
    align-self: center;
    height: 1px;
    background-color:#bdbdbd ;
    min-width: 260px;
    margin-top: 3px;
  }