.HomeLandingimgBox {
  height: 100vh;
  overflow: hidden;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
}

.buttonbottom {
  width: 100%;
  height: 20%;
}
.Homesectionone {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}

.searchbar {
  width: 200%;
}

.searchbarbtn {
  width: 10%;
  margin-top: 25px;
}

.inputStyle {
  margin: 3px;
  padding: 6px;
  border: 2px solid #b6b6b6;
  border-radius: 3px;
  box-sizing: border-box;
  width: 128%;
  height: 52px;
}

.HomeLandingBox {
  grid-gap: 5px;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 1px;
}

.gridContainer {
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  height: 100vh;
  overflow: scroll;
  /* padding: 16px; */
  padding: 25px 2%;
}

@media (min-width: 400px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 700px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1000px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  border: 0.5px solid #c7c7c7;
  transition: 0.3s;
  height: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(133, 128, 128, 0.2);
}

.txteleps p {
  text-align: left;
  width: 240px;
  height: 50px;
  padding-left: 3%;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.searchBox {
  /* width: 100%; */
  display: flex;
  justify-content: right;
  flex-direction: row;
  align-items: center;
  /* margin: 24px 0; */
  padding: 0 12px;
}

.inputStyle {
  padding: 6px 6px 6px 12px;
  border: 1px solid #000000;
  border-radius: 3px;
  box-sizing: border-box;
  height: 52px;
  margin-right: 4px;
  flex: 1;
}

.searchBtn {
  padding: 0 32px;
  height: 40px;
  border: 0px solid #000000;
  background: #000000;
  font-size: 17px;
  color: white;
  border-radius: 4px;
}

.listItemTitle {
  margin: 12px 8px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.buttontop {
  width: 100%;
}
.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.searchField {
  width: 400px;
}
@media (max-width: 900px) {
  .toolbar {
    flex-direction: column;
  }
  .searchField {
    width: 200px;
  }
}
