
  
  
  
  
  
  
  .button {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(197, 50, 50);
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 2%;
    font-size: 15px;
    box-shadow: -12px 12px 26px rgba(255, 0, 0, 0.212);
  }
  
  .head {
    color: rgb(197, 50, 50);
  }
  
 
  .categoryBox {
    margin-left: 5%;
    height: auto;
    margin-top: 10px;
    justify-content: center;
    font-family: "NeueWorld-CondensedRegular";
    max-width: 100%;
}
  
  
  /* part3*/
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
    transition: 0.3s;
    height: 90px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: white;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(253, 114, 114, 0.5);
  }
  
  .name {
    margin-left: 0px;
    padding: 2px 16px;
    margin-top: 13px;
  }
  
  
  
  .packagesGrid {
    max-width: 2000px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    padding: 32px 3%;
  }
  
  @media (min-width: 500px) {
    .packagesGrid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  
  
  .packageCard {
    box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
    transition: 0.3s;
    border-radius: 8px;
    display: flex;
    background-color: white;
    padding-left: 24px;
    flex-direction: column;
  }
  
  
  
  .packageNameHead {
    flex-direction: row;
    
    background-color: white;
    display: flex;
    padding: 10px 0px;
  }
  
  .packageName {
    font-weight: 700;
   
    color: rgb(51, 93, 182);
  }
  .packageDescription {
    padding: 0px 10px;
  }
  
  
  
  
  