.HomeLandingBox {
    grid-gap: 5px;
    max-width: 100%;
    max-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 1px;
  }
  
  .HomeLandingMainBox {
    margin-left: 10px;
     border: 10px;
      width: 20%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
  }
  
  .HomeLandingimgBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
  }
  
  .appstore {
    height: 50px;
    width: 240px;
    font-family: Arial;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: bold;
    background-color: white;
    text-decoration: none;
    margin-top: 12px;
    border: 1px solid white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .appstoree {
    height: 50px;
    width: 240px;
    font-family: Arial;
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: bold;
    background-color:  rgb(223, 217, 217);
    text-decoration: none;
    margin-top: 12px;
    border: 1px solid white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .appstore:hover {
    background-color: rgb(243, 235, 235);
  }
  
  .appstore:focus {
    outline: none;
    background-color:  rgb(223, 217, 217);
  }
  
  .appstoreText{

    color: black;
    padding: 0px;
    margin: 0px;
    margin-left: 12px;
  }
  .appstoreTextt{
    color: black;
  padding: 0px;
  margin: 0px;
  margin-left: 12px;
  }
  
  .Homesectionone{
      width:80%;
      height: 33%;
      
  }
  
  .linkItem{
    color: rgb(12, 12, 12);
    text-decoration: none;
    margin: 5px;
  
  }
  
  .Readmorebtn {
    margin: 1%;
    border-radius: 20px;
    height: 6.8cm;
    width: 258px;
    border-radius: 4px;
    font-family: Arial;
    color: rgb(0, 0, 0);
    font-size: 17px;
    background: white;
    text-decoration: none;
    margin-top: 20px;
    border: 2px solid rgb(219, 210, 210);
    box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
  }  
  .Homesectionthree{
      display: flex;
      flex-direction: row;
  }
  .Homesectionfour{
    display: flex;
      flex-direction: row;  
  
  }
  .buttonbox{
    color: black;
    margin-top: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .buttonbox:hover{
  box-shadow: 8px 8px 8px 8px rgba(2, 1, 1, 0.2);
  }
  .buttontop{
   height: 60%;
    width:100%;
  }
  .buttonbottom{
    width:100%;
    height: 20%;
  }
  .Homesectionone{
    margin-left: 10px;
    display: flex;
    flex-direction:row;
  }
  .searchbar{
    width: 205%;
  }
  .searchbarbtn{
    width: 10%;
    margin-top: 25px;
  
  }
  .form {
    margin-top: 5px;
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 12px;
    width: 50%;
    min-width: 360px;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
  }
  .inputStyle {
    margin: 3px ;
    padding: 6px;
    border: 2px solid #b6b6b6;
    border-radius: 3px;
    box-sizing: border-box;
    width: 128%;
    height: 52px;
  }
  .submitBtn {
    margin-left: 355%;
    margin-top: 2px;
    height: 52px;
    padding: 7px 10px;
    border: 2px solid #b6b6b6  ;
    background: #dddfe6;
    width: 100%;
    font-size: 17px;
    color: white;
  }
  
  .HomeLandingBox {
      grid-gap: 5px;
      max-width: 100%;
      max-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    margin-top: 1px;
    }
  
    .gridContainer {
      max-width: 3000px;
      margin: 0 auto;
      display: grid;
      grid-gap: 1rem;
      /* padding: 16px; */
      padding: 25px 2%;
    }
    
    @media (min-width: 400px) {
      .gridContainer {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    
    @media (min-width: 900px) {
      .gridContainer {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  
    .card {
      box-shadow: 0 4px 8px 0 rgba(133, 128, 128, 0.2);
      transition: 0.3s;
      height: auto;
      border-radius: 8px;
      display: flex;
      align-items:flex-start;
      background-color: rgb(255, 255, 255);
      cursor: pointer;
    }
    
    .card:hover {
      box-shadow: 0 8px 16px 0 rgba(167, 20, 20, 0.849);
    }
    
    .txteleps p{
      text-align:left;
      width: 240px; 
      height: 50px;
      padding-left: 3%;
      text-transform: capitalize;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  
    
    
   