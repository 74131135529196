.gird1 {
  order: 1;
}
.gird2 {
  order: 2;
}
.card {
  height: 200px;
  border-radius: 16px;
}
.btn {
  border: 1px solid black;
  width: 150px;
  height: 50px;
  color: black;
  padding: 10px;
}
@media (max-width: 560px) {
  .gird1 {
    order: 2;
  }
  .gird2 {
    order: 1;
  }
}
