.popup{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: none;
    display: flex;
    
    
}
.inner{
    border: 2px solid rgb(228, 218, 218);
    padding: 15px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 50px;
    position: relative;
    
    width: 100%;
    max-width: 640px;
    background-color:  rgb(231, 228, 228);
}
.inner .close{
    position: absolute;
    top:16px;
    right:16px
}
.close{
    color: rgb(187, 37, 37);
    font-weight: bold;
}