.HomeLandingMainBox {
  margin-left: 10px;
  border: 10px;
  width: 20%;
  display: flex;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  box-shadow: 0 6px 10px 0 rgba(172, 170, 170, 0.2);
}

.profilesectionone {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.2);
  border: 1px solid rgb(228, 225, 225);
}
.cardDiv {
  border-radius: 16px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
.profiletwo {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.info {
  margin-left: 15px;
  font-weight: bold;
  width: 110%;
}
.pass {
  color: black;
  width: 22%;
}

.prof {
  color: black;
  width: 20%;
}

.profilesectiontwo {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.card {
  box-shadow: 0 2px 2px 0 rgba(68, 68, 68, 0.1);
  transition: 0.3s;
  height: 60px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 0.5px solid rgb(243, 237, 237);
}

.card:hover {
  border: 1px solid rgb(243, 237, 237);
  box-shadow: 0 2px 2px 0 rgba(68, 68, 68, 0.2);
}

.name {
  margin-left: 5px;
  padding: 2px 16px;
  margin-top: 13px;
}

.gridContainer {
  display: grid;
  grid-gap: 1rem;
  /* padding: 16px; */
  padding: 32px 5%;
}

@media (min-width: 600px) {
  .gridContainer {
    grid-template-columns: repeat(0, 3fr);
  }
}

@media (min-width: 900px) {
  .gridContainer {
    grid-template-columns: repeat(1, 3fr);
  }
}

.logout {
  margin: 0 24px 0 0;
  padding: 0;
  cursor: pointer;
}
