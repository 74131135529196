.container {
  height: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(68, 68, 68, 0.2);
}

.gridContainer {
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  padding: 25px 2%;
}
.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.searchField {
  width: 400px;
}
@media (min-width: 450px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 550px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1000px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 900px) {
  .toolbar {
    flex-direction: column;
  }
  .searchField {
    width: 200px;
  }
}

.card {
  transition: 0.3s;
  height: auto;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  border: 0.5px solid #c7c7c7;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(133, 128, 128, 0.2);
}

.searchBox {
  /* width: 100%; */
  display: flex;
  justify-content: right;
  flex-direction: row;
  align-items: center;
  /* margin: 24px 0; */
  padding: 0 12px;
}

.inputStyle {
  padding: 6px 6px 6px 12px;
  border: 1px solid #000000;
  border-radius: 3px;
  box-sizing: border-box;
  height: 52px;
  margin-right: 4px;
  flex: 1;
}

.searchBtn {
  padding: 0 32px;
  height: 40px;
  border: 0px solid #000000;
  background: #000000;
  font-size: 17px;
  color: white;
  border-radius: 4px;
}

.txteleps {
  font-weight: 500;
  padding: 8px;
}
